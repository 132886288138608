import React, { Fragment } from "react";
import { Heading, SubHeading } from "../Heading";

const FinalPage = ({ formData }) => {
  return (
    <Fragment>
      <Heading
        styles={{
          marginBottom: "28px",
          direction: "rtl",
          font: "normal normal bold 53px/69px Assistant",
        }}
      >
        {`${formData.name}, תודה רבה על זמנך!`}
      </Heading>
      <SubHeading
        styles={{
          marginBottom: "8px",
          font: "normal normal bold 30px/40px Assistant",
          direction: "rtl",
        }}
      >
        נהדר מהנתונים שהזנת נראה שקיימת זכאות.
      </SubHeading>
      <SubHeading
        styles={{
          font: "normal normal bold 25px/33px Assistant",
          direction: "rtl",
          padding: "0 56px",
        }}
      >
        נחזור אליך לבירור של פרטים אחרונים על מנת להשלים איתך את תהליך הבדיקה.
      </SubHeading>
    </Fragment>
  );
};

export default FinalPage;
