import React from "react"
import {Box, Typography} from "@mui/material"
import avatar from "../../assets/images/avatar.svg"
import arrowBack from "../../assets/images/arrow-back.png"
import terms from "../../assets/images/terms.png"
import {GiPadlock} from "react-icons/gi"
import {ImSpinner3} from "react-icons/im"

const FormContainer = ({
  children,
  handleBack,
  activeStep,
  handleShowTermsOfUse,
  complete,
}) => {
  return (
    <Box
      className="Form-Container"
      position="relative"
      display="flex"
      flexDirection="column"
      width={900}
      marginTop="85px"
      marginBottom="37px"
      sx={{
        "@media (max-width:1200px)": {
          width: "80%",
        },
        "@media (max-width:768px)": {
          width: "90%",
          marginTop: "70px",
        },
        "@media (max-width:538px)": {
          marginTop: "50px",
        },
      }}
    >
      {/* Avatar */}
      <Box
        sx={{
          width: "150px",
          height: "150px",
          position: "absolute",
          zIndex: 1,
          left: 0,
          right: 0,
          margin: "auto",
          top: "-75px",
          "@media (max-width:1200px)": {
            width: "120px",
            height: "120px",
            top: "-60px",
          },
          "@media (max-width:538px)": {
            width: "100px",
            height: "100px",
            top: "-50px",
          },
        }}
      >
        <img src={avatar} alt="Avatar" width="100%" height="100%"></img>
      </Box>
      <Box
        className="Form-Content"
        sx={{
          border: "3px solid #094AAC",
          backdropFilter: "blur(14px)",
          minHeight: "496px",
        }}
      >
        {/* Back Button */}
        {activeStep > 0 && !complete && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              right: "-30px",
              top: "50%",
              cursor: "pointer",
              height: "60px",
              width: "60px",

              "@media (max-width:768px)": {
                height: "40px",
                width: "40px",
                right: "-20px",
              },
            }}
            onClick={handleBack}
          >
            <img src={arrowBack} height="100%" width="100%" alt="back"></img>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          sx={{
            position: "relative",
            minHeight: "496px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            padding="40px"
            paddingTop="75px"
            sx={{
              "@media (max-width:1200px)": {
                padding: "20px",
                paddingTop: "60px",
              },
              "@media (max-width:538px)": {
                padding: "20px",
                paddingTop: "50px",
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
      {/* SSL */}
      <Box
        display="flex"
        flexDirection="column"
        gap="5px"
        alignItems="center"
        paddingTop="5px"
      >
        <Typography
          display="flex"
          sx={{
            direction: "rtl",
            textAlign: "left",
            font: "normal normal bold 20px/28px Assistant",
            letterSpacing: "0px",
            color: "#000000",
          }}
        >
          <GiPadlock style={{position: "relative", top: "4px", left: "5px"}} />
          פרטיך מוגנים באמצעות הצפנת SSL
        </Typography>
        <Typography
          sx={{
            textAlign: "right",
            font: "normal normal bold 20px/28px Assistant",
            letterSpacing: "0px",
            color: "#000000",
          }}
        >
          השאלון אורך פחות מדקה
          <ImSpinner3 style={{position: "relative", top: "4px", left: "5px"}} />
        </Typography>
      </Box>
      <Box
        sx={{
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
          "& img": {
            height: "40px",
          },
          "@media (max-width:768px)": {
            right: "10px",
            "& img": {
              height: "30px",
            },
          },
          "@media (max-width:538px)": {
            position: "relative",
            marginTop: "10px",
          },
        }}
      >
        <img
          src={terms}
          alt="Terms & Conditions"
          height="100%"
          onClick={handleShowTermsOfUse}
          style={{cursor: "pointer"}}
        ></img>
        <Typography
          sx={{
            font: "normal normal bold 14px/18px Assistant",
            letterSpacing: "0px",
            color: "#000000",
            "@media (max-width:768px)": {
              font: "normal normal bold 12px/18px Assistant",
            },
            "@media (max-width:538px)": {
              font: "normal normal bold 10px/18px Assistant",
            },
          }}
        >
          תנאי שימוש
        </Typography>
      </Box>
    </Box>
  )
}

export default FormContainer
