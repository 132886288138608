import React, { useState, Fragment, useEffect } from "react";
import { Typography } from "@mui/material";
import ReactGA from "react-ga";
import { Heading, SubHeading } from "../../AstrosNew/Heading";
import { SubmitButton } from "../../AstrosNew/Buttons";
import TextFieldCustom from "../TextField";

const StepOne = ({ handleSubmit, setFormData, formData }) => {
  const [name, setName] = useState(formData?.name || "");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setName(newValue);
    setError("");
  };
  const handleStepSubmit = () => {
    if (name?.length >= 2) {
      setFormData((prevState) => ({ ...prevState, name }));
      handleSubmit({ name });
    }
    if (name.length < 2) {
      setError(true);
    }
  };

  useEffect(() => {
    ReactGA.pageview("/Step-One");
  }, []);

  return (
    <Fragment>
      <Heading
        styles={{
          marginBottom: "21px",
        }}
      >
        היי! כאן יקיר
      </Heading>
      <SubHeading
        styles={{
          marginBottom: "52px",
          direction: "rtl",
        }}
      >
        במסלול כחול לבן סידרנו לך בדיקת זכאות מהירה לחיסכון בתנאי המשכנתא
        הנוכחית שלך.
        <br></br>
        בואו נבצע בדיקה קצרה שבסיומה נדע את זכאותכם ללא עלות! מה שמך?
      </SubHeading>
      <TextFieldCustom
        type="text"
        value={name}
        handleChange={handleChange}
      >
        {error && (
          <Typography
            sx={{
              position: "absolute",
              bottom: "-20px",
              font: "normal normal 500 12px/17px Assistant",
              color: "#FF0000",
            }}
          >
            מינימום שני תווים
          </Typography>
        )}
      </TextFieldCustom>
      <SubmitButton
        label="המשך"
        handleClick={handleStepSubmit}
        wrapperStyles={{ marginTop: "50px" }}
      />
    </Fragment>
  );
};

export default StepOne;
