import React, { Fragment } from "react";
import { Heading } from "../Heading";
import { Box } from "@mui/material";
import { OptionButton, SubmitButton } from "../Buttons";

const StepFour = ({ handleSubmit, setFormData, formData }) => {
  const [selectedOption, setSelectedOption] = React.useState(
    formData?.totalMortgageAmount || null
  );
  const options = [
    {
      id: 1,
      name: "option 1",
      value: "עד מיליון וחצי",
      label: "עד מיליון וחצי",
    },
    {
      id: 2,
      name: "option 2",
      value: "מיליון וחצי עד 3 מיליון",
      label: "מיליון וחצי עד 3 מיליון",
    },
    { id: 3, name: "option 3", value: "מעל 3 מיליון", label: "מעל 3 מיליון" },
  ];

  const submitStep = () => {
    if (selectedOption) {
      setFormData((prevState) => ({
        ...prevState,
        totalMortgageAmount: selectedOption,
      }));
      handleSubmit({ totalMortgageAmount: selectedOption });
    }
  };

  return (
    <Fragment>
      <Heading styles={{ marginBottom: "62px", direction: "rtl" }}>
        מה סה״כ גובה המשכנתאות?
      </Heading>
      <Box
        display="flex"
        gap="20px"
        sx={{
          "@media (max-width:538px)": {
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "10px",
          },
        }}
      >
        {options?.map((option) => (
          <OptionButton
            selected={option.value === selectedOption}
            label={option.label}
            key={option.id}
            handleClick={() => setSelectedOption(option.value)}
            buttonStyles={{ width: "100%" }}
          />
        ))}
      </Box>
      <SubmitButton
        label="המשך"
        handleClick={submitStep}
        wrapperStyles={{ marginTop: "50px" }}
      />
    </Fragment>
  );
};

export default StepFour;
