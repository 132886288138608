import React from "react";
import { Typography } from "@mui/material";

export const Heading = ({ children, styles = {} }) => {
  return (
    <Typography
      sx={{
        textAlign: "center",
        font: "normal normal bold 31px/41px Assistant",
        letterSpacing: "0px",
        color: "#000000",
        ...styles,
        "@media (max-width:1200px)": {
          font: "normal normal bold 28px/38px Assistant",
        },
        "@media (max-width:768px)": {
          font: "normal normal bold 26px/36px Assistant",
        },
        "@media (max-width:538px)": {
          font: "normal normal bold 24px/30px Assistant",
        },
      }}
    >
      {children}
    </Typography>
  );
};

export const SubHeading = ({ children, styles = {} }) => {
  return (
    <Typography
      sx={{
        textAlign: "center",
        font: "normal normal bold 22px/28px Assistant",
        letterSpacing: "0px",
        color: "#000000",
        ...styles,
        "@media (max-width:1200px)": {
          font: "normal normal bold 20px/28px Assistant",
        },
        "@media (max-width:768px)": {
          font: "normal normal bold 20px/28px Assistant",
        },
        "@media (max-width:538px)": {
          font: "normal normal bold 20px/26px Assistant",
          padding: "0px !important",
        },
      }}
    >
      {children}
    </Typography>
  );
};
