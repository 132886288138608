import React, { Fragment } from "react";
import { Heading } from "../Heading";
import { Box } from "@mui/material";
import { OptionButton, SubmitButton } from "../Buttons";

const StepTwo = ({ handleSubmit, setFormData, formData }) => {
  const [selectedOption, setSelectedOption] = React.useState(
    formData.propertiesOwned || null
  );
  const options = [
    { id: 1, value: "1", label: "1" },
    { id: 2, value: "none", label: "0" },
    { id: 3, value: "יותר מ-1", label: "יותר מ-1" },
  ];

  const submitStep = () => {
    if (selectedOption) {
      setFormData((prevState) => ({
        ...prevState,
        propertiesOwned: selectedOption,
      }));
      handleSubmit({ propertiesOwned: selectedOption });
    }
  };
  return (
    <Fragment>
      <Heading styles={{ marginBottom: "62px", direction: "rtl" }}>
        {`היי ${formData.name} , כמה נכסים יש בבעלותך היום?`}
      </Heading>
      <Box
        display="flex"
        gap="20px"
        sx={{
          "@media (max-width:538px)": {
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "10px",
          },
        }}
      >
        {options?.map((option) => (
          <OptionButton
            selected={option.value === selectedOption}
            label={option.label}
            key={option.id}
            handleClick={() => setSelectedOption(option.value)}
            buttonStyles={{ width: "100%" }}
          />
        ))}
      </Box>
      <SubmitButton
        label="המשך"
        handleClick={submitStep}
        wrapperStyles={{ marginTop: "50px" }}
      />
    </Fragment>
  );
};

export default StepTwo;
