import React, { Fragment } from "react";
import { Heading } from "../Heading";
import { Box } from "@mui/material";
import { OptionButton, SubmitButton } from "../Buttons";

const StepFive = ({ handleSubmit, setFormData, formData }) => {
  const [selectedOption, setSelectedOption] = React.useState(
    formData?.monthlyMortgageRepayment || null
  );
  const options = [
    { id: 1, value: "₪8000 ומעלה", label: "₪8000 ומעלה", direction: "rtl" },
    { id: 2, value: "₪5000 - ₪8000", label: "₪5000 - ₪8000", direction: "" },
    { id: 3, value: "₪1000- ₪5000", label: "₪1000- ₪5000", direction: "" },
  ];

  const submitStep = () => {
    if (selectedOption) {
      setFormData((prevState) => ({
        ...prevState,
        monthlyMortgageRepayment: selectedOption,
      }));
      handleSubmit({ monthlyMortgageRepayment: selectedOption });
    }
  };

  return (
    <Fragment>
      <Heading styles={{ marginBottom: "62px", direction: "rtl" }}>
        מה ההחזר החודשי לסה״כ המשכנתאות?
      </Heading>
      <Box
        display="flex"
        gap="20px"
        sx={{
          "@media (max-width:538px)": {
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "10px",
          },
        }}
      >
        {options?.map((option) => (
          <OptionButton
            selected={option.value === selectedOption}
            label={option.label}
            key={option.id}
            handleClick={() => setSelectedOption(option.value)}
            buttonStyles={{ width: "100%", direction: option.direction }}
          />
        ))}
      </Box>
      <SubmitButton
        label="המשך"
        handleClick={submitStep}
        wrapperStyles={{ marginTop: "50px" }}
      />
    </Fragment>
  );
};

export default StepFive;
