import React, { useState, Fragment } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Heading, SubHeading } from "../../AstrosNew/Heading";
import { SubmitButton } from "../../AstrosNew/Buttons";
import TextFieldCustom from "../TextField";

const StepSeven = ({ handleSubmit, setFormData, formData }) => {
  const [numError, setNumError] = useState(false);
  const [termsCheck, isTermsCheck] = useState(true);
  const [termsError, setTermsError] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(
    formData?.mobileNumber || ""
  );

  const handleMobileNumChange = (e) => {
    const inputValue = e.target.value;
    const isNumber = /^\d+$/.test(inputValue); // Check if the input consists only of digits
    if (isNumber || inputValue === "0") {
      setMobileNumber(inputValue);
      setNumError("");
    } else {
      setMobileNumber("");
    }
  };

  const handleCheckboxChange = (e) => {
    console.log(e.target.checked);
    isTermsCheck(e.target.checked);
    if (e.target.checked && termsError) {
      setTermsError(false);
    } else {
      setTermsError(true);
    }
  };

  const submitStep = () => {
    if (mobileNumber?.length === 10 && termsCheck) {
      setFormData((prevState) => ({
        ...prevState,
        mobileNumber,
      }));
      handleSubmit({ mobileNumber });
    }
    if (!termsCheck) {
      setTermsError(true);
    }
    if (mobileNumber?.length < 10 || mobileNumber?.length > 10) {
      setNumError(true);
    }
  };

  return (
    <Fragment>
      <Heading
        styles={{
          marginBottom: "21px",
          direction: "rtl",
          "@media (max-width:538px)": {
            padding: "0 10px",
            lineHeight: "35px",
            wordBreak: "break-word",
          },
        }}
      >
        {` מעולה , ${formData.name}`}
        <br></br>
        כבר מסיימים וצ׳יק צ׳ק נוכל לדעת את תוצאות הבדיקה!
      </Heading>
      <SubHeading
        styles={{
          marginBottom: "28px",
          direction: "rtl",
          "@media (max-width:538px)": {
            padding: "0 10px",
            lineHeight: "35px",
            wordBreak: "break-word",
          },
        }}
      >
        הזן בבקשה מספר נייד תקין לקבלת תוצאות הבדיקה.
      </SubHeading>
      <TextFieldCustom
        type="number"
        value={mobileNumber}
        handleChange={handleMobileNumChange}
      >
        {numError && (
          <Typography
            sx={{
              position: "absolute",
              bottom: "-15px",
              font: "normal normal 300 13px/17px Assistant",
              letterSpacing: "0px",
              color: "#FF0000",
            }}
          >
            נא להזין נייד תקין
          </Typography>
        )}
      </TextFieldCustom>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          position: "relative",
          width: "462px",
          margin: "auto",
          marginTop: "11px",
          "@media (max-width:768px)": {
            width: "80%",
          },
          "@media (max-width:538px)": {
            width: "90%",
          },
        }}
      >
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              font: "normal normal normal 15px/19px Assistant",
              letterSpacing: "0px",
              color: "#000000",
            },
          }}
          control={
            <Checkbox
              checked={termsCheck}
              value={termsCheck}
              onChange={handleCheckboxChange}
              size="small"
            />
          }
          label="אני מאשר את התקנון"
          labelPlacement="start"
        />
        {termsError && (
          <Typography
            sx={{
              position: "absolute",
              left: 0,
              font: "normal normal 300 13px/17px Assistant",
              letterSpacing: "0px",
              color: "#FF0202",
            }}
          >
            נא לאשר את תנאי השימוש
          </Typography>
        )}
      </Box>
      <SubmitButton
        label="המשך"
        handleClick={submitStep}
        wrapperStyles={{ marginTop: "10px" }}
      />
    </Fragment>
  );
};

export default StepSeven;
