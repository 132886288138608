import React from "react";
import { Box } from "@mui/material";

const Steps = ({ steps, activeStep }) => {
  return (
    <Box
      display="flex"
      gap="62px"
      marginTop="60px"
      sx={{
        position: "relative",
        direction: "rtl",
        "@media (max-width:1200px)": {
          gap: "35px",
          marginTop: "35px",
        },
        "@media (max-width:768px)": {
          gap: "20px",
        },
        "@media (max-width:538px)": {
          gap: "10px",
        },
      }}
    >
      <Box
        className="Steps-Connector"
        sx={{
          position: "absolute",
          width: "92%",
          height: "20px",
          background: "#C3C0C0",
          zIndex: -1,
          margin: "auto",
          left: 0,
          right: 0,
          top: "45%",
          "@media (max-width:1200px)": {
            height: "16px",
          },
          "@media (max-width:768px)": {
            height: "12px",
            top: "40%",
          },
          "@media (max-width:538px)": {
            height: "8px",
          },
        }}
      ></Box>
      {steps.map((s, i) => (
        <Step
          index={i}
          activeStep={activeStep}
          key={i}
        />
      ))}
    </Box>
  );
};

export default Steps;

const Step = ({ index, activeStep }) => {
  let isActiveStep = index === activeStep;
  return (
    <Box
      height={100}
      width={100}
      borderRadius={50}
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="#fff"
      sx={{
        background: "#C3C0C0",
        font: "normal normal 600 35px/46px Assistant",
        "@media (max-width:1200px)": {
          width: "75px",
          height: "75px",
        },
        "@media (max-width:768px)": {
          width: "55px",
          height: "55px",
        },
        "@media (max-width:538px)": {
          width: "40px",
          height: "40px",
          font: "normal normal 600 20px/35px Assistant",
        },
        ...(isActiveStep && {
          background: "#094AAC 0% 0% no-repeat padding-box",
        }),
      }}
    >
      {index + 1}
    </Box>
  );
};
