import React, { Fragment, useState } from "react";
import moment from "moment";
import { v4 } from "uuid";
import TagManager from "react-gtm-module";
import { Box, Typography } from "@mui/material";
import Logo from "../../assets/images/logo.png";
import FormContainer from "./FormContainer";
import StepOne from "./FormStepsComponents/StepOne";
import StepTwo from "./FormStepsComponents/StepTwo";
import StepThree from "./FormStepsComponents/StepThree";
import StepFour from "./FormStepsComponents/StepFour";
import StepFive from "./FormStepsComponents/StepFIve";
import StepSix from "./FormStepsComponents/StepSix";
import StepSeven from "./FormStepsComponents/StepSeven";
import Steps from "./Steps";
import TermsOfUseDialog from "./TermsOfUseModal";
import FinalPage from "./FormStepsComponents/FinalPage";
import { getStepTitle } from "../../helperFunctions/getStepTitle";
import * as consts from "../../consts"

const steps = ["", "", "", "", "", "", ""];

const stepsForm = {
  0: StepOne,
  1: StepTwo,
  2: StepThree,
  3: StepFour,
  4: StepFive,
  5: StepSix,
  6: StepSeven,
};

const MainPage = () => {
  const [id, setId] = React.useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = React.useState({
    name: "", //First 1
    propertiesOwned: "", //Step 2
    currentMortgages: "", //Step 3
    totalMortgageAmount: "", //Step 4
    monthlyMortgageRepayment: "", //Step 5
    additionalLoans: "", //Step 6
    mobileNumber: "", //Step 7
    complete: false,
    createdAt: null,
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleSubmit = async (payloadData) => {
    try {
      handleTagManager();
      if (activeStep === 6) {
        setFormData((prevState) => ({ ...prevState, complete: true }));
        TagManager.dataLayer({
          dataLayer: {
            event: "registration_complete",
            category: "Mortgage Blue Form",
            action: `Form Completed`,
          },
        });
        const payload = {
          ...formData,
          id,
          ...payloadData,
          ...(!formData.createdAt && {
            createdAt: moment(new Date()).format("Do MMM YYYY, h:mm a"),
          }),
        };
        await fetch(consts.zapierWebhookUrl, {
          method: "POST",
          body: JSON.stringify(payload),
        });
        localStorage.setItem("formId", v4());
      }
      handleNext();
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleTagManager = () => {
    let stepTitle = getStepTitle(activeStep);

    TagManager.dataLayer({
      dataLayer: {
        event: `step${activeStep + 1}_submit`,
        category: "Mortgage Blue Form",
        action: `Step Submitted ${activeStep + 1}`,
        label: stepTitle,
      },
    });
  };

  React.useEffect(() => {
    let formId = localStorage.getItem("formId");
    if (formId) {
      setId(formId);
    } else {
      let id = v4();
      localStorage.setItem("formId", id);
      setId(id);
    }
  }, []);

  const ActiveFormComponent = formData.complete
    ? FinalPage
    : stepsForm[activeStep] || null;
  return (
    <Fragment>
      <TermsOfUseDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />

      <Box
        className="mainPage"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        {/* Logo */}
        <Box
          height={140}
          marginBottom="23px"
          marginTop="26px"
          sx={{
            "@media (max-width:768px)": {
              height: "112px",
              width: "180px",
            },
            "@media (max-width:538px)": {
              height: "84px",
              width: "135px",
              marginTop: "20px",
              marginBottom: "15px",
            },
          }}
        >
          <img
            src={Logo}
            alt="logo"
            height="100%"
            width="100%"
          />
        </Box>
        {/* Main Heading */}
        <Typography
          sx={{
            font: "normal normal bold 50px/70px Assistant",
            letterSpacing: "0px",
            textAlign: "center",
            color: "#000000",
            padding: "0 20px",
            lineHeight: "50px",
            "@media (max-width:1200px)": {
              fontSize: "40px",
            },
            "@media (max-width:768px)": {
              fontSize: "30px",
              lineHeight: "35px",
            },
            "@media (max-width:538px)": {
              fontSize: "24px",
              lineHeight: "30px",
            },
          }}
        >
          בדיקת זכאות לחיסכון במשכנתא מבלי לקום מהכורסא
        </Typography>
        {!formData.complete && (
          <Steps
            activeStep={activeStep}
            steps={steps}
          />
        )}
        {/* Form-Container Start*/}
        <FormContainer
          complete={formData.complete}
          activeStep={activeStep}
          handleBack={handleBack}
          handleShowTermsOfUse={handleClickOpen}
        >
          <ActiveFormComponent
            handleSubmit={handleSubmit}
            formData={formData}
            setFormData={setFormData}
          />
        </FormContainer>
        {/* Form-Container End*/}
      </Box>
    </Fragment>
  );
};

export default MainPage;
