import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    direction: "rtl",
    borderRadius: 0,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: alpha("#094AAC", 0.1),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: alpha("#094AAC", 0.3),
    },
  },
}));

export default function TermsOfUseDialog({ open, handleClose }) {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      open={open}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 1,
          font: "normal normal bold 60px/60px Assistant",
          letterSpacing: "0px",
          color: "#000000",
          "@media (max-width:1200px)": {
            font: "normal normal bold 50px/60px Assistant",
          },
          "@media (max-width:768px)": {
            font: "normal normal bold 40px/60px Assistant",
          },
          "@media (max-width:538px)": {
            font: "normal normal bold 30px/60px Assistant",
          },
        }}
        id="customized-dialog-title"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>תנאי השימוש</span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: "#000000",
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          sx={{
            font: "normal normal bold 25px/33px Assistant",
            color: "#000000",
            "@media (max-width:1200px)": {
              font: "normal normal bold 23px/31px Assistant",
            },
            "@media (max-width:768px)": {
              font: "normal normal bold 21px/29px Assistant",
            },
            "@media (max-width:538px)": {
              font: "normal normal bold 18px/26px Assistant",
            },
          }}
        >
          תנאי שימוש באתר “מסלול כחול לבן ” <br />
          תקנון השימוש באתר הנ”ל נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים
          כאחד . <br />
          <br />
          1. קדימון
          <br />
          <br />
          אתר “מסלול כחול לבן” (להלן “האתר”) הוא אתר המשתמש כאתר (“ייעוץ
          משכנתאות”) ייצוגי עבור “ייעוץ משכנתאות” והנך מוזמן לקחת בו חלק בכפוף
          להסכמתך לתנאי השימוש אשר יפורטו להלן
          <br />
          <br />
          2. קדימון אתר “מסלול כחול לבן” (להלן “האתר”) הוא אתר המשתמש כאתר
          (“ייעוץ משכנתאות”) ייצוגי עבור “ייעוץ משכנתאות” והנך מוזמן לקחת בו חלק
          בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן.
          <br />
          <br />
          3. בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו, הורדות של
          קבצים, מדיה כגון תמונות וסרטונים והתכנים השונים המוצעים לקהל המבקרים
          עשויים להשתנות מעת לעת או בהתאם לסוג התוכן. הנהלת האתר שומרת לעצמה
          הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או אזכור
          מיוחד בערוצי האתר השונים. קניין רוחני
          <br />
          <br />
          4. האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה
          לרבות גרפיקות, סרטונים, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר
          אחר אשר מוצג באתר שייכים במלואם לאתר הנ”ל ומהווים קניין רוחני בלעדי של
          אתר “מסלול כחול לבן” ואין לעשות בהם שימוש ללא אישור כתוב מראש מאתר
          “מסלול כחול לבן .” בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או
          לעבד פיסות קוד, גרפיקות, סרטונים, סימנים מסחריים או כל מדיה ותוכן אחר
          מבלי שיש ברשותכם אישור כתוב מראש.
          <br />
          <br />
          5. תוכן האתר אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך
          יתכנו בשל שיקולים טכניים, תקלות צד ג או אחרים, הפרעות בזמינות האתר.
          ולכן איננו יכולים להתחייב כי האתר יהיה זמין לכם בכל עת ולא יינתן כל
          פיצוי כספי או אחר בשל הפסקת השירות / הורדת האתר.
          <br />
          <br />
          6. קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים,
          איכותיים או אמינים וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום
          האחריות הבלעדי של המשתמש באתר.
          <br />
          <br />
          7. התכנים המוצעים באתר הינם בבעלותם הבלעדית של “מסלול כחול לבן” ואין
          לעשות בהם שימוש אשר נועד את האמור בתקנון זה (ראה סעיף 3) למעט במקרים
          בהם צוין אחרת או במקרים בהם צוין כי זכויות היוצרים שייכים לגוף חיצוני.
          במקרים אלו יש לבדוק מהם תנאי השימוש בקישור המצורף ולפעול על פי המצוין
          באתר החיצוני לו שייכים התכנים.
          <br />
          <br />
          8. ניהול משתמשים ומבקרים באתר הנהלת האתר שומרת לעצמה את הזכות לחסום כל
          משתמש ובין אם על ידי חסימת כתובת ה IP של המחשב שלו, כתובת ה MACID של
          המחשב שלו או אפילו בהתאם למדינת המוצא ללא צורך לספק תירוץ אשר מקובל על
          הגולש.
          <br />
          <br />
          9. צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי המשתמשים
          הרשומים באתר / מנויים הרשומים באתר. במקרים בהם יעלה בידיו של צד שלישי
          להשיג גישה למידע מוסכם בזאת כי לגולשים, משתמשים וחברים באתר לה תהה כל
          תביעה, טענה או דרישה כלפי צוות האתר “מסלול כחול לבן”.
          <br />
          <br />
          10. גילוי נאות באתר זה עשוי לעשות שימוש בקבצי קוקיז ( במיוחד עבור
          משתמשים רשומים ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד
          סטטיסטי אנונימי של גולשים וניתוח תנועת הגולש/ים, הרגלי גלישה באתר
          וניתוח קליקים וזמן שהייה. בכל העת ולבד מאשר גולשים המחוברים לאתר המידע
          הנשמר הוא אנונימי לחלוטין ואין בו את שם הגולש או כל פרט מזהה אחר.
          <br />
          <br />
          איזור שיפוט בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחולקת אתם
          מסכימים להלן כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי של החוק
          הישראלי תוך שימוש במערכת בתי המשפט הישראליים בלבד במחוז תל אביב
        </Typography>
      </DialogContent>
    </BootstrapDialog>
  );
}
