import React from "react";
import { Box, Button, alpha } from "@mui/material";

export const SubmitButton = ({
  label,
  handleClick,
  wrapperStyles = {},
  buttonStyles = {},
  ...props
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ ...wrapperStyles }}
    >
      <Button
        {...props}
        onClick={handleClick}
        sx={{
          border: "none",
          background: "#094aac 0% 0% no-repeat padding-box",
          font: "normal normal bold 48px/63px Assistant",
          color: " #ffffff",
          padding: "12px",
          borderRadius: 0,
          width: "462px",
          "&:hover": {
            background: "#094aac 0% 0% no-repeat padding-box",
          },
          "@media (max-width:1200px)": {
            width: "65%",
          },
          "@media (max-width:768px)": {
            width: "80%",
            height: "64px",
            font: "normal normal bold 38px/53px Assistant",
          },
          "&.Mui-disabled": {
            background: alpha("#094aac", 0.4),
            color: alpha("#ffffff", 0.4),
          },
          ...buttonStyles,
        }}
      >
        {label}
      </Button>
    </Box>
  );
};

export const OptionButton = ({
  label,
  handleClick,
  buttonStyles = {},
  selected,
}) => {
  return (
    <Button
      onClick={handleClick}
      sx={{
        border: "none",
        minHeight: "84px",
        ...(selected && {
          background: "#094AAC",
          textAlign: "center",
          letterSpacing: "0px",
          color: "#FFFFFF",
          font: "normal normal bold 25px/33px Assistant",
        }),
        ...(!selected && {
          background: "#fffff",
          textAlign: "center",
          letterSpacing: "0px",
          color: "#000",
          font: "normal normal bold 23px/30px Assistant",
          border: "2px solid #094AAC",
        }),
        "&:hover": {
          background: selected ? "#094aac" : "#fff",
        },
        "@media (max-width:1200px)": {
          width: "65%",
          minHeight: "74px",
        },
        "@media (max-width:768px)": {
          width: "80%",
          minHeight: "64px",
        },
        "@media (max-width:538px)": {
          width: "90%",
          minHeight: "50px",
        },
        ...buttonStyles,
      }}
    >
      {label}
    </Button>
  );
};
