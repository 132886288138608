import React from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import * as consts from './consts'
import MainPage from "./Components/AstrosNew";
import "./App.css";
import { clarity } from 'react-microsoft-clarity'

const tagManagerArgs = {
  gtmId: consts.googleTagManagerId,
};

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(consts.googleAnalytics);
clarity.init(consts.microsoftClarityId)

function App() {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
