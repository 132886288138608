import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FaRegUser } from "react-icons/fa";
import { Box } from "@mui/material";
import { IoPhonePortraitOutline } from "react-icons/io5";

const TextFieldCustom = ({
  type,
  handleChange,
  value,
  wrapperStyles = {},
  children = null,
}) => {
  const icon = type === "text" ? <FaRegUser /> : <IoPhonePortraitOutline />;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      flexDirection="column"
      gap="5px"
      sx={{
        position: "relative",
        margin: "auto",
        width: "462px",
        "@media (max-width:1200px)": {
          width: "65%",
        },
        "@media (max-width:768px)": {
          width: "80%",
        },
        ...wrapperStyles,
      }}
    >
      <TextField
        required
        type="text"
        value={value}
        fullWidth
        onChange={handleChange}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
          dir: "rtl",
        }}
        sx={{
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#094AAC",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "2px solid #094AAC",
              borderRadius: "4px",
            },
        }}
        placeholder={type === "text" ? "מה שמך המלא?" : "מספר הטלפון הנייד"}
        inputProps={{
          sx: {
            direction: "rtl",
            "&::placeholder": {
              textAlign: "right",
              font: "normal normal bold 12px/15px Assistant",
              letterSpacing: "0px",
            },
          },
        }}
      />
      {children}
    </Box>
  );
};

export default TextFieldCustom;
