export const getStepTitle = (step) => {
  if (step === 0) {
    return "name  - שם";
  } else if (step === 1) {
    return "phase 1- כמה נכסים יש לך ";
  } else if (step === 2) {
    return "phase 2- כמה משכנתאות יש לך ";
  } else if (step === 3) {
    return "phase 3- סהכ גובה המשכנתאות";
  } else if (step === 4) {
    return "phase 4- החזר חודשי";
  } else if (step === 5) {
    return "phase 5-האם יש הלוואות הלוואות";
  } else if (step === 6) {
    return "phone- טלפון";
  }
};
